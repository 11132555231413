import React from "react"
import { Link } from "gatsby"

import { FiPhoneCall } from "react-icons/fi"
import logo from "../../images/logo.png"

const Header = () => (
  <header className="header">
    <div className="header__content">
      <div className="header__logobox">
        <Link to="/">
          <img className="header__logo" src={logo} alt="Logo EQTHERM" />
        </Link>
        <div className="header__contact">
          <span className="header__contact--header">
            Skontaktuj się z nami:
          </span>
          <span>
            <FiPhoneCall /> 507 304 435
          </span>
          <span>
            <FiPhoneCall /> 575 505 250
          </span>
        </div>
      </div>
      <nav className="header__links">
        <Link to="/o-nas">
          <span className="header__link">O nas</span>
        </Link>
        <Link to="/produkty">
          <span className="header__link">Produkty</span>
        </Link>
        <Link to="/kontakt">
          <span className="header__link">Kontakt</span>
        </Link>
      </nav>
    </div>
  </header>
)

export default Header
