import React from "react"
import { Link } from "gatsby"

import { FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi"

const Footer = () => (
  <footer className="footer">
    <div className="footer__content">
      <div className="footer__links">
        <ul>
          <li>
            <Link to="/o-nas">O nas</Link>
          </li>
          <li>
            <Link to="/produkty">Produkty</Link>
          </li>
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </ul>
      </div>
      <div className="footer__contact">
        <ul>
          <li className="footer__link">
            <FiMail /> <a href="mailto:biuro@eqtherm.pl">biuro@eqtherm.pl</a>
          </li>
          <li className="footer__link footer__link--shiftedright">
            <a href="mailto:szmilewski@eqtherm.pl">szmilewski@eqtherm.pl</a>
          </li>
          <li className="footer__link">
            <FiPhoneCall /> 575-505-250
          </li>
          <li className="footer__link footer__link--shiftedright">507-304-435</li>
          <li className="footer__link">
            <FiMapPin /> Przasnyska 6A/352A
          </li>
          <li className="footer__link footer__link--shiftedright">01-756 Warszawa</li>
        </ul>
      </div>
      <div className="footer__copyright">
        <p>NIP: 847-159-91-82</p>
        <p>&copy; EQTHERM {new Date().getFullYear()}</p>
      </div>
    </div>
  </footer>
)

export default Footer
